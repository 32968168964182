.contactUsBase {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #fbfbfb;
}
.contactUsContent {
  width: 70%;
  min-width: 1000px;
  height: 100%;
  max-width: 1500px;
  border-radius: 20px;
  height: auto;
  display: grid;
  grid-template-columns: 65% 35%;
  filter: drop-shadow(0 0 0.75rem #2f2e56);
}
.contactRight {
  background-color: #2f2e56;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 20px;
  .formTop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 80px;
    .formTItleDiv {
      width: auto;
      display: flex;
      align-items: center;
      .formTitleRight {
        font-size: 40px;
        color: #fff;
        font-family: "Times New Roman", Times, serif;
        font-weight: 600;
        text-align: center;
        margin: 0px;
      }
    }
    .formImg {
      width: auto;
      height: auto;
      .formInfoImg {
        width: 70px;
      }
    }
  }
  .rightContentDiv {
    width: 100%;
    .titleTxtSub {
      color: #fff;
      font-size: 20px;
      font-weight: 700;
      text-align: start;
    }
    .subHrRight {
      height: 5px;
      width: 15%;
      background-color: #fff;
    }
    .hrRight {
      height: 1px;
      width: 100%;
      background-color: #fff;
      margin-bottom: 10px;
    }
    .contactInfo {
      display: flex;
      justify-content: space-between;
      width: 80%;
      align-items: center;
      .contactDataImg {
        .contactIcon {
          width: 30px;
          height: 30px;
        }
      }
      .contactDataTxt {
        padding: 15px 0px;
        .contactTxt {
          color: #fff;
          font-size: 20px;
          font-weight: 500;
          margin: 0px;
        }
      }
    }
  }
}
.contactLeft {
  background-color: #fff;
  padding: 5%;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.form {
  width: 100%;
  height: auto;
  .formTop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 80px;
    .formTItleDiv {
      width: auto;
      .formTitle {
        font-size: 40px;
        color: #2f2e56;
        font-family: "Times New Roman", Times, serif;
        font-weight: 600;
        text-align: center;
        margin: 0px;
      }
      .hrForm {
        background-color: #2f2e56;
        height: 5px;
        width: 70px;
        margin-top: 10px;
        border-radius: 10px;
        margin-bottom: 20px;
      }
    }

    .formImg {
      width: auto;
      height: auto;
      .formMailImg {
        width: 100px;
      }
    }
  }

  .formContent {
    .formContentTop {
      display: flex;
      justify-content: space-between;
      .field_area {
        width: 45%;
        padding-bottom: 5%;
        .lableText {
          color: #9e9e9e;
          font-size: 17px;
        }
        .textField {
          width: 100%;
          height: 40px;
          background-color: transparent;
          border: none;
          border-bottom: 1px solid #9e9e9e;
          outline: none;
          font-size: 15px;
          color: #2f2e56;
        }
        .error {
          color: red;
          font-size: 13px;
          font-weight: 500;
        }
      }
    }
    .formContentBottom {
      width: 100%;
      position: relative;
      margin-bottom: 20px;
      .lableText {
        color: #9e9e9e;
        font-size: 17px;
      }
      .textArea {
        background-color: transparent;
        width: 98%;
        border: none;
        border-bottom: 1px solid #9e9e9e;
        outline: none;
        margin-top: 10px;
      }
      .error {
        color: red;
        font-size: 13px;
        font-weight: 500;
      }
      .formBtnDiv {
        position: absolute;
        bottom: -10px;
        right: -10px;
        .submitBtn {
          background-color: #2f2e56;
          width: 80px;
          height: 80px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          border: none;
          outline: none;
          cursor: pointer;
          .submitIcon {
            width: 50px;
            height: 50px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .contactUsContent {
    min-width: 600px;
  }
  .formImg {
    display: none;
  }
  .formTop {
    width: 100%;
  }
  .contactIcon {
    width: 20px !important;
    height: 20px !important;
  }
  .contactTxt {
    font-size: 14px !important;
    padding-left: 10px;
  }
  .formTitle {
    font-size: 30px !important;
  }
  .formTitleRight {
    font-size: 30px !important;
  }
}
@media screen and (max-width: 600px) {
  .contactUsContent {
    min-width: 300px;
    grid-template-columns: 100%;
  }
.contactLeft{
  border-top-right-radius: 20px;
  border-bottom-left-radius: 0px;
}
.contactRight{
  border-top-right-radius: 0px;
  border-bottom-left-radius: 20px;
}
}