.footerBase {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  background-color: #1e1e26;
  padding-top: 50px;
  padding-bottom: 50px;
}
.footerContent {
  width: 70%;
  min-width: 1000px;
  height: 100%;
  max-width: 1500px;
  border-radius: 20px;
  height: auto;
}
.footerTop {
  width: 100%;
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
}
.footerGrid {
  width: 100%;
}
.logoFooter {
  height: 80px;
  cursor: pointer;
}
.footerDesp {
  color: #fff;
  padding: 10px;
  font-size: 12px;
}
.footerHeadTxt {
  color: #fff;
  padding: 5px;
  font-size: 25px;
  margin: 0px;
  font-weight: 700;
}
.footerLinkTxt {
  color: #fff;
  padding: 5px;
  cursor: pointer;
}
.footerLine {
  height: 2px;
  width: 100%;
  background-color: #fff;
}
.copyTxt {
  color: #fff;
}
.footerBottomDiv {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.footerIcons {
  width: 100%;
  display: flex;
  // justify-content: space-between;
  .socialIcon {
    width: 45px;
    height: 45px;
    cursor: pointer;
    margin-left: 10px;
  }
}
@media screen and (max-width: 1000px) {
  .footerContent {
    min-width: 0px;
  }
  .footerTop {
    grid-template-columns: 50% 50%;
  }
  .footerDesp {
    text-align: center;
  }
  .footerHeadTxt {
    text-align: center;
  }
  .footerLinkTxt {
    text-align: center;
  }
  .copyTxt {
    text-align: center;
  }
  .logoFooter {
    display: flex;
    justify-content: center;
  }
  .footerIcons {
    justify-content: space-between;
   
  }
}
@media screen and (max-width: 600px) {
  .footerTop {
    grid-template-columns: 100%;
  }
}
