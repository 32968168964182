.doorBase {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding-top: 50px;
}
.doorContent {
  width: 70%;
  display: grid;
  min-width: 1000px;
  height: 100%;
  max-width: 1500px;
  grid-template-columns: 35% 65%;
}
.doorFlexBase {
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
}
.doorLeftContent {
  width: 100%;
  height: auto;
}
.doorRightTxt {
  padding: 2%;
}
.doorRightContent {
  width: 100%;
  height: auto;
  justify-self: right;
}
.leftDoorImageDiv {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.doorImage {
  width: 450px;
  height: 500px;
  object-fit: cover;
}
.doorTitle {
  margin: 0px;
  color: #26399f;
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 5px;
}
.doorMainTitle {
  margin: 0px;
  color: #000;
  font-weight: 700;
  font-size: 30px;
  width: 40%;
  margin-bottom: 15px;
}
.doorMainDesp {
  margin: 0px;
  width: 60%;
  color: #000;
  font-size: 15px;
  margin-bottom: 15px;
  line-height: 25px;
}
.flexDoorSubContent {
  width: 100%;
  height: 250px;
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
}
.miniDoorData {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-end;
  margin-top: 5%;
}
.miniContent {
  width: 96%;
  height: 100%;
}
.miniDoor {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.miniImgDiv {
  width: 100%;
  height: 80%;
}
.miniTxtDiv {
  width: 100%;
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.miniTxt {
  color: #000;
  margin: 0px;
  text-align: center;
  font-size: 17px;
  font-weight: 600;
}

@media only screen and (max-width: 1850px) {
  .flexDoorSubContent {
    height: auto;
  }
  .doorImage {
    width: 350px;
    height: 400px;
  }
  .miniTxt {
    font-size: 15px;
  }
}
@media only screen and (max-width: 1000px) {
  .doorContent {
    width: 100%;
    min-width: 0px;
    grid-template-columns: auto;
  }
  .doorLeftContent {
    display: none;
  }
  .miniDoorData {
    justify-content: center;
  }
  .doorMainTitle {
    width: 80%;
  }
  .doorMainDesp {
    width: 80%;
  }
}
@media only screen and (max-width: 600px) {
  .flexDoorSubContent {
    grid-template-columns: 50% 50%;
  }
}
