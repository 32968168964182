.aboutBase {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding-top: 30px;
}
.aboutContent {
  width: 70%;
  display: grid;
  min-width: 1000px;
  max-width: 1500px;
  grid-template-columns: 50% 50%;
}
.aboutFlexBase {
  display: grid;
  align-items: center;
}
.leftContent {
  width: 90%;
  justify-self: start;
  height: auto;
}
.rightContent {
  width: 90%;
  justify-self: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 500px;
}

.aboutTitle {
  margin: 0px;
  color: #0f0c39;
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 5px;
}
.aboutMainTitle {
  margin: 0px;
  color: #000;
  font-weight: 600;
  font-size: 30px;
  width: 50%;
  margin-bottom: 15px;
  font-family: 'Times New Roman', Times, serif;
}
.aboutMainDesp {
  margin: 0px;
  color: #000;
  font-size: 15px;
  margin-bottom: 15px;
  line-height: 25px;
}
.blueBtn {
  width: 150px;
  height: 40px;
  border-radius: 10px;
  border: none;
  outline: none;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  margin-top: 10px;
  background-color: #0f0c39;
}
.aboutimgDiv {
  width: 450px;
  height: 450px;
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.imageBorderDiv {
  border: 2px solid #0f0c39;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: -10px;
  left: -10px;
}
.aboutImage {
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
  border-radius: 10px;
  z-index: 999;
}

@media only screen and (max-width: 1000px) {
  .aboutContent {
    grid-template-columns: auto;
    width: 90%;
  }
  .aboutContent {
    min-width: 0px;
  }
  .leftContent {
    width: 100%;
  }
  .rightContent {
    width: 100%;
    justify-content: center;
    padding-top: 20px;
    height: auto;
  }
}
@media only screen and (max-width: 600px) {
  .aboutimgDiv {
    width: 300px;
    height: 300px;
    margin-top: 20px;
  }
}
