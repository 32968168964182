.helpBase {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  background-color: #fff;
  padding-top: 50px;
}
.helpContent {
  width: 70%;
  min-width: 1000px;
  height: 100%;
  max-width: 1500px;
  border-radius: 20px;
  height: auto;
}
.helpImg {
  width: 100%;
}

@media screen and (max-width: 1000px) {
  .helpContent {
    min-width: 600px;
  }
}

@media screen and (max-width: 600px) {
  .helpContent {
    min-width: 0px;
    width: 90%;
  }
}
