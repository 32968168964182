.centerNotFOund {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notFoundImg{
    width: 50%;
    z-index: -10;
}
