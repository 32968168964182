.productBase {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  //   padding-top: 50px;
}
.productContent {
  width: 70%;
  min-width: 1000px;
  height: 100%;
  max-width: 1500px;
  position: relative;
}
.productFilter {
  padding: 4%;
  width: 92%;
}
.productDataDiv {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 25% 75%;
}
.productDataList {
  padding: 1%;
  width: 98%;
}
.detailsDiv {
  display: grid;
  grid-template-columns: 30% 5% 65%;
}
.productListDiv {
  display: grid;
  grid-template-columns: 50% 50%;
}
.contentDataDiv {
  width: 100%;
  display: flex;
  justify-content: center;
}
.productCardDiv {
  background-color: #fff;
  filter: drop-shadow(0 0 0.35rem #2f2e569c);
  width: 95%;
  height: auto;
  margin-bottom: 5%;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 40% 60%;
}
.cardImgDiv {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.cardImg {
  width: 150px;
  max-width: 96%;
  padding: 1% 2%;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
}
.cardDataDiv {
  padding: 20px;
  display: flex;
  align-items: center;
  .ailgnCenter {
    .productTitle {
      margin: 0px;
      font-size: 20px;
      font-weight: 700;
      color: #000;
    }
    .productAmount {
      color: #4659a1;
      margin: 0px;
      margin-top: 5px;
      font-size: 20px;
      font-weight: 900;
    }
    .productDesp {
      color: #999;
      margin: 0px;
      margin-top: 5px;
      font-size: 15px;

      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3; /* number of lines to show */
      line-clamp: 3;
      -webkit-box-orient: vertical;
    }
    .productDespBlk {
      color: #000;
      margin: 0px;
      margin-top: 5px;
      font-size: 15px;
      font-weight: 500;
    }
    .contactBtn {
      background-color: #4659a1;
      padding: 10px 20px;
      border: none;
      outline: none;
      border-radius: 5px;
      color: #fff;
      margin-top: 20px;
      cursor: pointer;
    }
    .navBtnTxt {
      color: #fff;
      margin: 0px;
    }
  }
}

.productDetailsPopUp {
  position: absolute;
  padding: 20px 0px;
  width: 50%;
  min-width: 800px;
  overflow-y: auto;
  height: auto;
  background-color: #fff;
  top: 0px;
  right: 10%;
  filter: drop-shadow(0 0 0.35rem #2f2e569c);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.popupCenter {
  width: 100%;
  height: 100%;
  position: relative;
}
.closeBtn {
  position: absolute;
  width: 50px;
  height: 50px;
  right: 20px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
}
.closeIcon {
  width: 30px;
  height: 30px;
}
.caroselDiv {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  .caroselCardDiv {
    width: 60%;
  }
}
.productCardBase {
  width: 100%;
  .productImg {
    height: 300px;
    object-fit: contain;
  }
}

.carousel .thumbs-wrapper {
  margin: 0px;
}

.productSortDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 100%;
}
.searchTxtBox {
  width: 95%;
  height: 35px;
  border: 1px solid #999;
  padding-left: 20px;
  border-radius: 10px;
}

.filterTitleBase {
  display: flex;
  align-items: center;
  height: 80px;
  width: 100%;
  border-radius: 10px;
}
.filterTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: auto;
  width: 92%;
  background-color: #ebebeb;
  border: 1px solid #4659a1;
  border-radius: 10px;
  padding: 10px 4%;
}
.filterTxt {
  font-size: 20px;
  color: #000;
  font-weight: 600;
  margin: 0px;
}
.filterBtn {
  background-color: transparent;
  border: none;
  outline: none;
  .filterIcon {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
}
.hrFilter {
  background-color: #999;
  width: 100%;
  height: 1px;
  margin-top: 20px;
}
.filterSession {
  background-color: #ebebeb;
  //   filter: drop-shadow(0 0 0.35rem #2f2e569c);
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #4659a1;
}
.sessionTitleTxt {
  margin: 0px;
  font-size: 18px;
  color: #4659a1;
  font-weight: 700;
  margin: 10px 0px;
}
.type {
  .checkBox {
    margin-right: 10px;
  }
  label {
    font-size: 15px;
    color: #000;
    font-weight: 500;
  }
}

@media only screen and (max-width: 1000px) {
  .productDetailsPopUp {
    min-width: 500px;
  }
  .productCardBase {
    .productImg {
      height: 200px;
    }
  }
  .productContent {
    min-width: 600px;
  }
  .productListDiv {
    grid-template-columns: 100%;
  }
  .type {
    label {
      font-size: 12px;
    }
  }
}
@media only screen and (max-width: 600px) {
  .productDetailsPopUp {
    min-width: 300px;
    width: 90%;
    right: 5%;
  }
  .productCardBase {
    .productImg {
      height: 100px;
    }
  }
  .cardImgDiv {
    justify-content: center;
  }
  .productContent {
    min-width: 300px;
  }
  .productCardDiv {
    grid-template-columns: 100%;
  }
  .productDataDiv {
    grid-template-columns: 100%;
  }
}

@media only screen and (max-width: 475px) {
  .productDetailsPopUp {
    right: 0%;
  }
}
.pagination {
  display: flex;
  justify-content: center;
}
