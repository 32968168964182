.carCardBase {
  width: 100%;
  height: auto;
  position: relative;
}
.carImg {
  width: 100%;
  height: 700px;
  object-fit: cover;
}
.carDataDiv {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 999;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
}
.carContent {
  width: 70%;
  min-width: 1000px;
  max-width: 1500px;
  height: 300px;
  bottom: 0;
}
.carTopContent {
  width: 100%;
  display: flex;
  align-items: center;
  height: 200px;
}
.carLogo {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  // border: 2px solid #fff;
}
.carTitle {
  color: #fff;
  font-size: 55px;
  font-weight: 600;
  width: 60%;
  text-align: start;
  font-family: "Times New Roman", Times, serif;
  padding-left: 1%;
}
.carBtnTxt {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  text-align: start;
  padding-left: 1%;
}
.carButton {
  height: 100px;
  display: flex;
  align-items: center;
}
.arrowImg {
  width: 5px;
}
.carBtnArrow {
  width: 50px;
  height: 50px;
  padding: 15px;
  border-radius: 50%;
  border: 2px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

@media only screen and (max-width: 1000px) {
  .carTitle {
    width: 100%;
    font-size: 30px;
  }
  .carLogo {
    width: 100px;
    height: 100px;
  }
  .carContent {
    min-width: 0px;
  }
}
@media only screen and (max-width: 600px) {
  .carImg {
    height: 80vh;
  }
  .carTitle {
    font-size: 20px;
    height: 100px;
  }
  .arrowImg {
    width: 10px;
  }
}
