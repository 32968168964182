.headerBase {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding-top: 10px;
  box-shadow: 0px 15px 10px -15px #999;    
}
.headerContent {
  width: 70%;
  height: 80px;
  min-width: 1000px;
  max-width: 1500px;
  display: flex;
  justify-content: space-between;
}
.respHeaderContent {
  display: none;
}
.headerDropdown {
  display: none;
}
.headerFlex {
  width: auto;
  height: 100%;
  display: flex;
  align-items: center;
}
.logoImg {
  height: 80%;
  padding: 10px;
}
.navTxtDiv {
  padding-left: 20px;
}
.navTxt {
  color: #000;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
}
.navTxt:hover {
  color: #2c168b;
}
.searchBtn {
  background-color: #fff;
  height: 40px;
  width: 100%;
  border-radius: 15px;
  border: none;
  outline: none;
  padding-left: 20px;
  font-size: 15px;
  filter: drop-shadow(0 0 0.2rem rgba(0, 0, 0, 0.404));
}
.navBtnDiv {
  padding-left: 20px;
  position: relative;
  display: flex;
  align-items: center;
  width: auto;
}
.searchIcon {
  height: 25px;
  width: 25px;
  position: absolute;
  right: 10px;
}

@media only screen and (max-width: 1000px) {
  .headerContent {
    display: none;
  }
  .respHeaderContent {
    display: block;
    height: 80px;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    justify-content: space-between;
  }
  .repHeaderFlex {
    height: 100%;
    width: auto;
    display: flex;
    align-items: center;
  }
  .headerDropdown {
    display: block;
    z-index: 100;
  }
  .logoImgResp {
    height: 70%;
  }
  .respIcon {
    height: 35px;
    width: 35px;
  }
  .btnDiv {
    background-color: transparent;
    border: none;
    outline: none;
  }
  .headerDropdown {
    position: absolute;
    width: 100%;
    height: auto;
    top: 80px;
    background-color: #fff;
    padding-bottom: 20px;
    box-shadow: 0px 15px 10px -15px #111;
  }
  .navTxtResp {
    text-align: center;
  }
  .navBtnDiv {
    padding-left: 0px;
    display: flex;
    justify-content: center;
  }
  .searchBtn {
    width: 70%;
    border: 1px solid #000;
    filter: drop-shadow(0 0 0.75rem rgba(255, 255, 255, 0.404));
  }
  .searchIcon {
    right: 16%;
  }
}
