.testimonyBase {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  background-color: #fff;
  padding: 50px 0px;
}
.testimonyContent {
  width: 70%;
  min-width: 1000px;
  height: 100%;
  max-width: 1500px;
  border-radius: 20px;
  height: auto;
}
.testimonyGrid {
  width: 100%;
  height: auto;
  display: grid;
  margin-top: 40px;
  grid-template-columns: 33.3% 33.3% 33.3%;
}
.testimonyDataBase {
  display: flex;
  width: 100%;
  justify-content: center;
  .testimonyData {
    width: 80%;
    padding: 5%;
    background-color: #fff;
    filter: drop-shadow(0 0 0.35rem #2f2e569c);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    border-radius: 15px;
    .testDespTxt {
      color: #000;
      font-size: 16px;
      text-align: center;
    }
    .starImgDiv {
      display: flex;
      justify-content: center;
      width: 100%;
      margin: 30px 0px;
      .starImg {
        width: 50%;
      }
    }
    .testNameTxt {
      color: #000;
      font-size: 20px;
      text-align: center;
      font-weight: 700;
      margin: 0px;
    }
    .testLocationTxt {
      color: #4659a1;
      font-size: 18px;
      text-align: center;
      font-weight: 700;
      margin: 0px;
    }
  }
}
.testimonyTitle {
  color: #4659a1;
  font-size: 25px;
  font-weight: 700;
  margin: 0px;
  margin-bottom: 10px;
}
.testimonySubTitle {
  color: #000;
  font-size: 40px;
  font-family: "Times New Roman", Times, serif;
  font-weight: 900;
  margin: 0px;
}

@media screen and (max-width: 1000px) {
  .testimonyContent {
    min-width: 600px;
  }
  .testimonyGrid {
    grid-template-columns: 100%;
  }
  .testimonyData {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 600px) {
  .testimonyContent {
    min-width: 300px;
  }
  .testimonySubTitle {
    font-size: 35px;
  }
}
