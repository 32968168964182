.galleryBase {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding-top: 50px;
}
.galleryContent {
  width: 70%;
  min-width: 1000px;
  height: 100%;
  max-width: 1500px;
}
.imgGalleryRow {
  display: -ms-flexbox; /* IE10 */
  display: flex;
  -ms-flex-wrap: wrap; /* IE10 */
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 4px;
}
.imgGalleryColumn {
  -ms-flex: 33%; /* IE10 */
  flex: 33%;
  max-width: 33%;
}

.longImgDiv {
  width: 100%;
  height: 500px;
  padding-top:2%;
}

.shortImgDiv {
  width: 100%;
  padding-top: 2%;
  height: 250px;
}

.galleryImg {
  border-radius: 10px;
  height: 98%;
  width: 98%;
  padding: 1%;
  object-fit: cover;
}

.titleTxt {
  font-size: 30px;
  color: #000;
  font-family: "Times New Roman", Times, serif;
  font-weight: 600;
  text-align: center;
}

.buttonGallery {
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.blueBtn {
  width: 150px;
  height: 40px;
  border-radius: 10px;
  border: none;
  outline: none;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  margin-top: 10px;
  background-color: #0f0c39;
}

@media screen and (max-width: 1000px) {
  .galleryContent {
    min-width: 600px;
  }
  .longImgDiv {
    height: 250px;
  }

  .shortImgDiv {
    height: 125px;
  }
}

@media screen and (max-width: 600px) {
  .galleryContent {
    min-width: 300px;
  }
  .imgGalleryColumn {
    -ms-flex: 100%;
    flex: 100%;
    max-width: 100%;
  }
}
